<template>
  <div class="signList">
    <div class="ui-tab">
     <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="listComponent.loading"
              :finished="listComponent.finished"
              :error="listComponent.error"
              finished-text="没有更多了"
              error-text="请求失败，点击重新加载"
              @load="loadMore"
            >
      <van-tabs v-model:active="active" @change="listChange">
        <van-tab v-for="(item,index) in tabNav" :title="item.name" :key="index" :val="item.value">
            <template v-if="typeSign == 'waitSign003'">
              <p class="tips">系统已为您匹配产品，请在{{dataInfo.signLimtTime}}小时之内完成签约，超时会取消！</p>
            </template>
            <template v-if="typeSign == 'waitMoney004'">
              <p class="tips">请在今天{{dataInfo.payMoneyTime}}之前完成打款，超时会取消，周末及节假日除外！如您已完成打款，请忽略此提示。</p>
            </template>
          
          <!-- <p>请在今天'+payMoneyTime+'之前完成打款，超时会取消，周末及节假日除外！如您已完成打款，请忽略此提示。</p> -->
          <ul>
            <li v-for="item in ListData">
              <div class="title">
                <span><i></i>{{item.productName}}</span>
                <!--签约状态-->
                <template v-if="item.queueStatus == 1">
                    <span class="purple">排队中</span>
                </template>
                <template v-else-if="item.queueStatus == 2">
                    <span class="red">已匹配</span>
                </template>
                <template v-else-if="item.reserveStatus == 1 || item.reserveStatus == 3 || item.reserveStatus == 5">
                    <span class="blue">{{item.statusStr}}</span><!--待签约-->
                </template>
                <template v-else-if="item.reserveStatus == 4 || item.reserveStatus == 7 ">
                    <span class="darkBlue">{{item.statusStr}}</span><!--待成立-->
                </template>
                <template v-else-if="item.queueStatus == 3 || item.reserveStatus == 6 || item.reserveStatus == 11 || item.reserveStatus == 12 || item.reserveStatus == 13 ">
                    <span class="grey">{{item.statusStr}}</span><!--已取消-->
                </template>
                <template v-else-if="item.reserveStatus == 8">
                    <span class="green">{{item.statusStr}}</span><!--已成立-->
                </template>
                <template v-else-if="item.reserveStatus == 10">
                    <span class="orange">{{item.statusStr}}</span><!--已兑付-->
                </template>
                 <!--签约状态end-->
              </div>
              <div class="listCon">
                <span class="money"><em>{{item.reserveAmount | formatMoney}}元</em></span>
                <!--业绩比较基准-->
                <template v-if="item.reserveStatus == 1 || item.reserveStatus == 3 || item.reserveStatus == 5 || item.reserveStatus == 4 || item.reserveStatus == 7 || item.reserveStatus == 8 || item.reserveStatus == 6 || item.reserveStatus == 10 || item.reserveStatus == 11 || item.reserveStatus == 12 || item.reserveStatus == 13 || item.queueStatus == 3 ">
                    <span>业绩比较基准
                      <template v-if="item.basicRate == 0">
                      <b>{{item.profitRate}}</b>
                      </template>
                      <template v-else-if="item.floatInterestRate == 0">
                      <b>{{item.basicStairRate}}%</b>
                      </template>
                      <template v-else> 
                      <b>{{item.basicStairRate}}%+{{item.floatInterestRate}}%</b>
                      </template>
                    </span>
                </template>
                <!--业绩比较基准end-->
                <template v-if="item.floatRateInfo!=''">
                   <span class="exclusive">({{item.floatRateInfo}})</span>
                </template>
                <span>认购金额</span>
                <span>项目期限
                  <template v-if="item.reservePeriodType == 1">
                    <b>{{item.reservePeriod}}个月</b>
                  </template>
                  <template v-else> 
                    <b>{{item.reservePeriod}}天</b>
                  </template>
                </span>
              </div>
              <!--取消倒计时-->
              <template v-if="item.nowTime > item.matchTimePlus || item.matchTimePlus == 'undefined'">
                <template v-if="item.reserveStatus == 1 || item.reserveStatus == 3 || item.reserveStatus == 5 || item.reserveStatus == 4 || item.reserveStatus == 7">
                    <div class="time">
                      <i>取消倒计时：</i>
                      <!--倒计时-->
                      <van-count-down :time="item.matchTimePlus">
                        <template #default="timeData">
                          <span class="block">{{ timeData.hours }}</span>
                          <span class="colon">:</span>
                          <span class="block">{{ timeData.minutes }}</span>
                          <span class="colon">:</span>
                          <span class="block">{{ timeData.seconds }}</span>
                        </template>
                      </van-count-down>
                      <!--end倒计时-->
                    </div>
                </template>
              </template>
              <template v-else-if="item.queueStatus == 3 ">
                  <div class="time">排队已取消
                    <i>取消时间：</i>
                    <!--倒计时-->
                    {{item.createTime | formateDate}}
                    <!--end倒计时-->
                  </div>
              </template>
              <!--取消倒计时end-->
              <template v-else-if="item.reserveStatus == 8 && item.productDueDateDesc && item.productValueDateDesc">
                  <div class="time">
                    <span><i>预计到期日：</i>{{item.productDueDateDesc}}</span>
                    <span><i>收益起算日：</i>{{item.productValueDateDesc}}</span>
                  </div>
              </template>
              <template v-else-if="item.reserveStatus == 10 && item.actDueDateDesc && item.productValueDateDesc">
                  <div class="time">
                    <span><i>实际到期日：</i>{{item.actDueDateDesc}}</span>
                    <span><i>收益起算日：</i>{{item.productValueDateDesc}}</span>
                  </div>
              </template>
              <!--按钮-->
              <div class="listBtn">
                <template v-if="item.signType==0">
                  <!---->
                  <template v-if="item.reserveStatus == 4 || item.reserveStatus == 7">
                      <template v-if="item.queueStatus == 4 ||  item.payStatus == 0 " >
                          <span class="cancel" @click="cancel(item.reserveId)">取消</span>
                          <span class="goSign" @click="upLoadCertificate(item.reserveId)">上传凭条</span> 
                      </template>
                      <template v-else-if="item.payStatus == 1" >
                          <p class="goSign oneBtn" @click="upLoadCertificate(item.reserveId)">上传凭条</p> 
                      </template>
                  </template>
                  <template v-else-if="item.reserveStatus == 1 || item.reserveStatus == 3 || item.reserveStatus == 5">
                    <template v-if="item.signFlowNo==null ">
                        <span class="cancel" @click="cancel(item.reserveId)">取消</span>
                        <span class="goSign" @click="goSign(item.productId,item.reserveId,item.signType)">去签约</span> 
                    </template>
                    <template v-else-if="item.signStatus==0 ">
                      <span class="cancel" @click="cancel(item.reserveId)">取消</span>
                      <span class="goSign" @click="goSignature()">去签约</span>
                    </template>    
                  </template>
                  <template v-else-if="item.queueStatus == 1 || item.queueStatus == 2"><!--//排队中-->
                    <span class="cancel" @click="cancelLine(item.reserveId)">取消</span>
                    <span class="lookSignDetail()" >查看</span> 
                  </template>
                  <!---->
                </template> 
               </div>
              <!--按钮end-->
              <template v-if="item.signType==1 || item.reserveStatus==4 || item.reserveStatus==6|| item.reserveStatus==7|| item.reserveStatus==8|| item.reserveStatus==9|| item.reserveStatus==10|| item.reserveStatus==11">
                <div class="arrow" @click="lookSignDetail(item.reserveId,item.signType)"><van-icon name="arrow" /></div>
              </template>
             
            </li>
          </ul>
        </van-tab>
      </van-tabs>
       </van-list>
      </van-pull-refresh>
    </div>

  </div>
</template>
<script>
import { canaelReserve,allSignList,cancelLine} from "../services/proApi";
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserIsRealName} from "../services/api";
import { Dialog } from 'vant';
import basic from "../utils/basic";

export default {
  data() {
    return {
      listComponent: {
        loading: true,
        finished: true,
        error: false,
        page: 1
      },
      refreshing:true,
      active:0,
     tabNav: [{name: '全部'},{name: '待签约'},{name: '待成立'},{name: '已成立'},{name: '已分配'},{name: '已取消'},
      ],
      typeSign:'all001',
      ListData: [],
      page: 1,
      dataInfo:[],

    };
  },
  created: function() {
    var _this = this;
     _this.onRefresh();
   
  },
  methods: {
    listChange(val){//获取tab切换参数
     this.typeSign=""
      switch(val){
        case 0:
        var  typeSign = 'all001'
        var typeSignStr = '全部'
          break;
        // case 1:
        // var  typeSign = 'line002'
        // var typeSignStr = '排队中'
        //   break;
        case 1:
        var  typeSign = 'waitSign003'
        var typeSignStr = '待签约'
          break;
        case 2:
        var  typeSign = 'waitMoney004'
        var typeSignStr = '待成立'
          break;
        case 3:
        var  typeSign = 'setUp005'
        var typeSignStr = '已成立'
          break;
        case 4:
        var  typeSign = 'cash007'
        var typeSignStr = '已分配'
          break;
        default:
        var typeSign = 'cancel006'
        var typeSignStr = '已取消'
      }
      this.typeSign=typeSign;
      this.ListData=[];
      this.listComponent.page=1;
      this.allSignList(typeSign);
    },
    async onRefresh() {
      this.ListData = [];
      this.listComponent.page = 1;
       this.allSignList(this.typeSign);
    },
   loadMore() {
      this.listComponent.page += 1;
      this.allSignList(this.typeSign);
    },
    cancel(reserveId){
      Dialog.confirm({
      title: '温馨提示',
      message:
        '您确定取消预约该产品吗？',
      })
      .then(() => {
        // on confirm
        this.canaelReserve(reserveId)
      })
      .catch(() => {
        // on cancel
      });
    },
  async allSignList(typeSign){//列表接口
    this.listComponent.loading = true;
    const rows = 10;
    const datas = await allSignList({
      page: this.listComponent.page,
      pageSize: rows,
      typeSign:typeSign
    });
    this.listComponent.loading = false;
    this.refreshing = false;
      if (datas.code === 1) {
        const list = datas.data.pageDto.records;
        this.dataInfo=datas.data;
        if (list.length) {
          this.ListData = this.ListData.concat(list);
        }
        this.listComponent.finished = !list || (this.ListData?.length == datas?.data.pageDto.pages);
       
        if(this.listComponent.page==datas.data.pageDto.pages){
          this.listComponent.finished =true
        }
      }
   },
   async canaelReserve(reserveId){//取消接口
      const datas = await canaelReserve({reserveId:reserveId});
        if (datas.code === 1) {
          this.$router.go(0)
          this.allSignList(this.typeSign);
        } 
   },
   async cancelLine(reserveId){//取消排队接口
      const datas = await cancelLine({lineReserveId:reserveId});
        if (datas.code === 1) {
          Dialog.confirm({
            title: '温馨提示',
            message:
              '您确定取消预约该产品吗？',
            })
            .then(() => {
              // on confirm
               this.$router.go(0)
            })
            .catch(() => {});
         
        } 
   },
   async goSign(productId,reserveId,signType){
      const _this = this;
      const datas = await queryUserIsRealName({ });
      let encrypted = datas.data.encrypted;
      let data = datas.data.msg;
      let aseKey = decryptRSA(encrypted); //RSA解密
      let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
      console.log(res);
      //status 0是未绑定 1是已绑卡
      let bindCardStatus=res.bindCardStatus;
      if(bindCardStatus==0){
        Dialog.confirm({
        title: '请完成银行卡绑定',
        showCancelButton:false,
        confirmButtonText:'去绑卡',
        message:
          '您尚未绑定银行卡，请根据指引完成银行卡绑定！',
        })
        .then(() => {
          // on confirm
          _this.$router.push({
            path: '/realNameBank',
            query: {}
          });
        })
        .catch(() => {});
        
      }else{
        _this.$router.push({
          path: '/signDetail',
          query: {
            productId: productId,
            reserveId:reserveId,
            signType:signType,
          }
        });
      }
   },
   goSignature(){//去签章提醒
      Dialog.confirm({
        title: '请完成电子合同签署',
        showCancelButton:false,
        confirmButtonText:'知道了',
        message:
          '请注意查收短信，点击短信中的链接，进行电子合同签章，完成最终签约！',
        })
        .then(() => {
          // on confirm
        })
        .catch(() => {});
   },
   lookSignDetail(reserveId,signType){//查看详情
     this.$router.push({
        path: "lookSignDetail", query: {reserveId:reserveId,signType:signType}
      });
   },
   upLoadCertificate(reserveId){//上传凭证
     this.$router.push({
        path: "upLoadCertificate", query: {reserveId:reserveId}
      });
   },
  },
  filters:{
    formatMoney: function(money) {
      money = money > 9999 ? money / 10000 + "万" : money;
      return money;
    },
    formateDate:function(now){
      var now = parseInt(now);
      var d = new Date(now);
      return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()
    }, 
  }, 
};
</script>

<style scoped lang="scss">
.signList{
   .tips{
      color: #ee0a24;
      text-align: center;
      padding: 0.5rem;
      line-height: 1.2rem;
   }
   ul{
     li{
       padding:1rem 0 0 0;color:#999;margin-bottom:1rem;background: #fff;position: relative;
       .title{
         font-size: 1rem;display: flex;justify-content: space-between;padding-bottom:1rem;
         color:#333;
         i{border-left: 0.2rem solid #0398FF;padding-right: 0.3rem;}
         span{width: 80%;}
         span:last-child{
          font-size: 0.8rem;
          color: #FFFEFE;
          width: 7rem;
          height: 0.8rem;
          border-radius: 1rem 0 0 0;
          text-align: center;
          line-height: 0.8rem;
         }
         .red{background: linear-gradient(127deg,rgba(251,43,45,0.99),rgba(250,71,34,0.99));}
         .purple{ background:linear-gradient(127deg,rgba(133,98,254,0.99),rgba(231,141,233,0.99));}
         .green{background: linear-gradient(127deg,rgba(10,196,151,0.99),rgba(119,235,140,0.99));}
         .blue{background: -webkit-linear-gradient(left, #0398FF, #356BFF);}
         .grey{background:linear-gradient(127deg,rgba(185,183,183,0.99),rgba(153,153,153,0.99));}
         .orange{background: linear-gradient(127deg,rgba(255,150,0,0.99),rgba(255,194,0,0.99));}
         .darkBlue{background:linear-gradient(127deg,rgba(98,120,255,0.99),rgba(48,174,255,0.99));}
       }
       div{
         padding-left: 1rem;
         .money{color:#ee0a24}
         span{
           width: 50%;
          text-align: left;
          display: inline-block;
          padding: 0.4rem 0;
          line-height:1.3rem;
          em{color:#ee0a24;font-size: 1.3rem;}
          b{color:#333;font-size: 1.1rem;padding-left:0.2rem}
         }
         
       }
       .listCon{
         position: relative;
         border-bottom: 0.01rem solid #ddd;
         padding-bottom:1rem;
         .exclusive{
            height: 0.5rem;
            line-height: 0.5rem;
            position: absolute;
            right: 5rem;
            background: red;
            color: #eee;
            font-size: 0.26667rem;
            border-radius: 0.9rem 0.24rem 0.5rem 0;
            width: 6rem;
            top: -1.1rem;
            text-align: center;
         }
       }
       .time{
         margin-top: 0.5rem;
         div{padding-left: 0;}
         span{color:#0398FF}
         i{color:#333}
         .block{ width: 1.3rem;text-align: center;padding:0.2rem 0}
         .colon{width: auto;}
       }
      .listBtn{
        color:#333;
        span,p{
          line-height: 2.2rem;font-size:0.9rem;text-align: center;
        }
        p.oneBtn{width: 100%;padding:0.4rem 0}
        i{color:#0398FF}
        .goSign{text-align: center;width: 49%;color:#356BFF}
        .cancel{border-right: 0.01rem solid #ddd;color:#333}
        p{text-align: center;color:#0398FF;}
      }
      .arrow{
        position: absolute;
        right: 1rem;
        top: 5rem;
        font-size: 1.3rem;
      }

     }
   }
}
</style>
<style lang='css'>
.van-count-down{line-height: inherit;}
.colon {
    display: inline-block;
    margin: 0 0.4rem;
    color: #ee0a24;
  }
  .block {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #666162;
    padding:0.3rem;
  }
  .van-count-down{display: inline-block;}
</style>
